import React, { useEffect, useState } from "react";
import { url } from "../Constants";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import swal from "sweetalert";
import { Button, Modal, OverlayTrigger } from "react-bootstrap";
import API from "../../Api/Api";
import moment from "moment/moment";
import { MdArchive, MdBook, MdDelete, MdEdit, MdMail } from "react-icons/md";
import Tooltip from "react-bootstrap/Tooltip";
import Editor from "../LmsAdminDashboard/Editor/Editor";
import CredentialModal from "./User Application Profile/CredentialModa";

const UserApplicaton = () => {
  const { id } = useParams("");
  const [indata, setIndata] = useState([]);
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [fromEmails, setFromEmails] = useState([]);
  const [acceptance_deadline, setAcceptance_deadline] = useState("");
  const [documentType, setdocumentType] = useState("");
  const [offerView, setOfferView] = useState(false);
  const [docInputVisible, setDocInputVisible] = useState(false);
  const [viewId, setViewId] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [viewIdStatus, setViewIdStatus] = useState("");

  const [actionModal, setActionModal] = useState({
    notes: false,
    mail: false,
    email: "",
    user: "",
  });
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    url: "",
    modal: false,
  });

  const [mail, setMail] = useState({
    subject: "",
    body: "",
    signature: "",
  });

  const [notes, setNotes] = useState("");
  const [allNotes, setAllNotes] = useState({
    data: [],
    loading: false,
  });

  const [allMails, setAllMails] = useState({
    data: [],
    loading: false,
  });

  const getData = async (id) => {
    const res = await fetch(`${url}/application/admin/get`, {
      mode: "cors",
      method: "POST",
      referrerPolicy: "origin-when-cross-origin",
      headers: {
        token: authToken,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ userId: id }),
    });

    const json = await res.json();
    // console.log(json);
    // console.log(json)

    if (res.status === 200) {
      setIndata(json.data);
    } else {
      alert("no data available");
    }
    setIsLoading(false);
  };

  const updateStatus = async (status, appId, acceptance_deadline, documentType) => {
    const res = await fetch(`${url}/application/admin/status`, {
      mode: "cors",
      method: "POST",
      referrerPolicy: "origin-when-cross-origin",
      headers: {
        token: authToken,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        userId: id,
        status: status,
        id: appId,
        acceptance_deadline: acceptance_deadline
          ? moment(acceptance_deadline).format("MM-DD-YYYY")
          : null,
        documentType: documentType,
      }),
    });

    const json = await res.json();

    if (json.success === true) {
      swal({
        title: "Success",
        text: "Status updated",
        icon: "success",
        button: "Ok",
      });
      await getData(id);
    } else {
      swal({
        title: "Error",
        text: json.message,
        icon: "error",
        button: "Ok",
      });
    }

    await getData(id);
  };

  const statusValues = [
    "Shortlisted",
    "Pre-Application Documents",
    "Applied",
    "Submitted to Institution",
    "Under Review",
    "Offer",
    "Rejected by Institution",
    "Post Application Documents",
    "Accepted",
    "Canceled",
    "Deferred",
    "Waitlisted",
  ];

  useEffect(() => {
    getData(id);
    getFromEmails();
  }, [id]);

  const getFromEmails = async () => {
    try {
      const response = await API("get", "/mass-mail/from-emails");
      setFromEmails(response.data.data);
    } catch (error) { }
  };

  const getAllNotesForAUser = async (application) => {
    setAllNotes({ data: [], loading: true });
    try {
      const response = await fetch(`${url}/note/read`, {
        method: "POST",
        mode: "cors",
        referrerPolicy: "origin-when-cross-origin",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("token"),
        },
        body: JSON.stringify({
          application: application,
          // createdBy: localStorage?.getItem("_id"),
        }),
      });
      const json = await response.json();
      setAllNotes({ data: json?.data, loading: false });
    } catch (error) {
      swal({
        title: "Error!",
        text: error?.response.message,
        icon: "error",
        button: "Ok!",
      });
      setAllNotes({ data: [], loading: false });
    }
  };

  const getAllMailsForAUser = async (user) => {
    setAllMails({ data: [], loading: true });
    try {
      const response = await fetch(`${url}/send-email/${user}`, {
        method: "GET",
        mode: "cors",
        referrerPolicy: "origin-when-cross-origin",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("token"),
        },
      });
      const json = await response.json();
      setAllMails({ data: json?.data, loading: false });
    } catch (error) {
      swal({
        title: "Error!",
        text: error?.response.message,
        icon: "error",
        button: "Ok!",
      });
      setAllMails({ data: [], loading: false });
    }
  };

  const handleAddNotes = async () => {
    try {
      const response = await API("post", `${url}/note/create`, {
        note: notes,
        application: actionModal?.application,
        createdBy: localStorage?.getItem("_id"),
        type: 'application'
      });
      const json = response.data;
      swal({
        title: "Success!",
        text: json?.message,
        icon: "success",
        button: "Ok!",
      });
      setActionModal({ notes: false, mail: false });
      setNotes("");
    } catch (error) {
    } finally {
      setActionModal({ notes: false, mail: false });
    }
  };

  const handleSendMail = async () => {
    try {
      const res = await API("post", `${url}/send-email`, {
        createdBy: localStorage?.getItem("_id"),
        application: actionModal?.application,
        to: actionModal?.email,
        subject: mail?.subject,
        body: mail?.body,
        signature: mail?.signature,
      });
      swal({
        title: "Success!",
        text: res?.data?.message,
        icon: "success",
        button: "Ok!",
      });
      // window.location.reload();
      setActionModal({ notes: false, mail: false });
    } catch (error) {
      setActionModal({ notes: false, mail: true });
    } finally {
    }
  };

  const handleReSendMail = async (mailid) => {
    try {
      const response = await fetch(`${url}/send-email/resend`, {
        method: "POST",
        mode: "cors",
        referrerPolicy: "origin-when-cross-origin",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("token"),
        },
        body: JSON.stringify({
          mailid: mailid,
        }),
      });
      const json = await response.json();
      swal({
        title: "Success!",
        text: json?.message,
        icon: "success",
        button: "Ok!",
      });
      setMail({ subject: "", body: "" });
      setActionModal({ notes: false, mail: false });
    } catch (error) {
      swal({
        title: "Error!",
        text: error?.response.message,
        icon: "error",
        button: "Ok!",
      });
    } finally {
      setActionModal({ notes: false, mail: false });
    }
  };

  const handleCloseCredModal = () => {
    setCredentials({
      ...credentials,
      modal: false,
    });
  };

  const delApplication = async (aid) => {
    try {
      const response = await API("delete", `/application/admin/del/${aid}`);
      const json = response.data;
      swal({
        title: "Success!",
        text: json?.message,
        icon: "success",
        button: "Ok!",
      });
      getData(id);
    } catch (error) {
    } finally {
    }
  };

  const deleteCredentials = async (aid) => {
    try {
      const response = await API(
        "delete",
        `/application/admin/del/creds/${aid}`
      );
      const json = response.data;
      swal({
        title: "Success!",
        text: json?.message,
        icon: "success",
        button: "Ok!",
      });
      getData(id);
    } catch (error) {
    } finally {
    }
  };

  return (
    <div className="body-content no-filterpanel">
      <div className="main-heading mb-3">View Application(s)</div>
      <div className="body-panel">
        <div className="d-flex justify-content-between">
          <OverlayTrigger
            key="bottom"
            placement="bottom"
            overlay={<Tooltip id="tooltip">View / Send Email</Tooltip>}
          >
            <button
              onClick={() => {
                setActionModal({
                  mail: true,
                  notes: false,
                  email: indata[0]?.email,
                  userid: indata[0]?._id,
                });
                let body = `
            <table className="table table-striped table-hover applicationDashboard">
            <thead>
              <tr>
                <th scope="col">Application ID</th>
                <th scope="col">Country</th>
                <th scope="col">University/College</th>
                <th scope="col">Course Name</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
            `;
                indata.map(
                  (item, idx) =>
                  (body += `
                <tr> 
                <th scope="row">${item.applicationId}</th>
                <td>${item.country}</td>
                <td>${item.universityName}</td>
                <td>${item.courseName}</td>
                <td>${item.applicationStatus}</td>
                </tr>
              `)
                );
                body += ` </tbody>  </table>`;
                setMail({
                  body,
                });
                getAllMailsForAUser(indata[0]?._id);
              }}
              className="btn useDeleteBtn"
            >
              <MdMail style={{ color: "#DC7215" }} /> View / Send Email
            </button>
          </OverlayTrigger>
          <div>
            <button
              className="btn btn-primary"
              onClick={() =>
                navigate(`/dashboard/user/createapplication/${id}`)
              }
            >
              Create Application
            </button>
          </div>
        </div>

        <br />
        <div className="body-container">
          {isLoading && <CircularProgress />}
          {!isLoading && indata.length === 0 ? (
            <div className="body-container dashboard ">
              No applications found
            </div>
          ) : (
            ""
          )}
          {!isLoading && indata.length > 0 ? (
            <div className="body-container dashboard grid-frame mt-3">
              <div className="row">
                <div className="col">
                  <table className="table table-striped table-hover applicationDashboard">
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Country</th>
                        <th scope="col">University/College</th>
                        <th scope="col">Scholarship Awarded</th>
                        <th scope="col">Course Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {indata.map((item, idx) => (
                        <>
                          <tr key={idx}>
                            <th scope="row">{item.applicationId}</th>
                            <td>{item.country}</td>
                            <td>
                              {item.universityName}
                              <br />
                              <br />
                              <div>
                                {item?.credentials?.map((itm) => (
                                  <ul
                                    className="credentials-list"
                                    key={itm?._id}
                                  >
                                    <li>
                                      <strong>Email</strong> : {itm?.email}
                                    </li>
                                    <li>
                                      <strong>Password</strong> :{" "}
                                      {itm?.password}
                                    </li>
                                    <li>
                                      <strong>URL</strong> :{" "}
                                      <a href={itm?.url} target="_blank">
                                        <i className="fa-solid fa-arrow-up-right-from-square"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <MdEdit
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          setCredentials({
                                            modal: true,
                                            email: itm?.email,
                                            password: itm?.password,
                                            url: itm?.url,
                                            id: itm?._id,
                                          })
                                        }
                                      />{" "}
                                      <MdDelete
                                        style={{
                                          cursor: "pointer",
                                          color: "red",
                                        }}
                                        onClick={() =>
                                          swal({
                                            title: "Are you sure?",
                                            text: "Once deleted, you will not be able to recover this credentials!",
                                            icon: "warning",
                                            buttons: true,
                                            dangerMode: true,
                                          }).then((res) => {
                                            if (res) {
                                              deleteCredentials(itm?._id);
                                            }
                                          })
                                        }
                                      />
                                    </li>
                                  </ul>
                                ))}
                              </div>
                            </td>
                            <td>
                              {item?.scholarshipAwardedAmount
                                ? "$ " + item?.scholarshipAwardedAmount
                                : "-"}
                            </td>
                            <td>{item.courseName}</td>
                            <td>
                              {/* Display acceptance deadline if the application status matches */}
                              {["Offer", "Pre-Application Documents", "Post Application Documents"].includes(item.applicationStatus) && (
                                <p>
                                  Acceptance Deadline:
                                  <span className="badge bg-secondary">
                                    {item.acceptance_deadline
                                      ? moment.utc(item?.acceptance_deadline).format("YYYY-MM-DD")
                                      : "Not Set"}
                                  </span>
                                </p>
                              )}
                              {item.documentType && (
                                  <p>
                                    Document Type:{" "}
                                    <span className="badge bg-info">{item.documentType}</span>
                                  </p>
                              )}
                              {/* Dropdown to select status */}
                              <select
                                name="status"
                                id={idx}
                                defaultValue={item.applicationStatus}
                                onChange={(e) => {
                                  const selectedStatus = e.target.value;
                                  setViewIdStatus(selectedStatus);
                                  setViewId(item._id); // Save the current item's ID for inputs

                                  if (["Pre-Application Documents", "Post Application Documents"].includes(selectedStatus)) {
                                    setOfferView(false); // Hide offer-specific logic
                                    setDocInputVisible(true); // Show inputs for documents and deadline
                                  } else if (selectedStatus === "Offer") {
                                    setOfferView(true); // Show offer-specific inputs
                                    setDocInputVisible(false); // Hide document-specific inputs
                                  } else {
                                    setOfferView(false);
                                    setDocInputVisible(false); // Hide all additional inputs
                                    updateStatus(selectedStatus, item._id, null, null); // Directly update the status
                                  }
                                }}
                              >
                                {statusValues.map((status, idx) => (
                                  <option key={idx}>{status}</option>
                                ))}
                              </select>

                              {/* Show inputs for "Offer" */}
                              {offerView && item._id === viewId && (
                                <div className="d-flex gap-2 mt-2">
                                  <input
                                    type="date"
                                    className="form-control"
                                    value={acceptance_deadline}
                                    onChange={(e) => setAcceptance_deadline(e.target.value)}
                                  />
                                  <button
                                    className="btn btn-secondary"
                                    onClick={() => {
                                      if (acceptance_deadline) {
                                        updateStatus(viewIdStatus, item._id, acceptance_deadline, null); // Use viewIdStatus for status
                                        setAcceptance_deadline(""); // Clear input
                                        setOfferView(false); // Hide inputs
                                      } else {
                                        swal("Error", "Please select an acceptance deadline", "error");
                                      }
                                    }}
                                  >
                                    Update
                                  </button>
                                </div>
                              )}

                              {/* Show inputs for "Pre-Application Documents" and "Post-Application Documents" */}
                              {docInputVisible && item._id === viewId && (
                                <div className="d-flex flex-column gap-2 mt-2">
                                  <input
                                    type="date"
                                    className="form-control"
                                    value={acceptance_deadline}
                                    onChange={(e) => setAcceptance_deadline(e.target.value)}
                                  />
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Document Type"
                                    value={documentType}
                                    onChange={(e) => setdocumentType(e.target.value)}
                                  />
                                  <button
                                    className="btn btn-secondary"
                                    onClick={() => {
                                      if (acceptance_deadline && documentType) {
                                        // Use the updated updateStatus function
                                        updateStatus(viewIdStatus, viewId, acceptance_deadline, documentType);

                                        // Clear input fields and hide the document input section
                                        setdocumentType(""); // Clear document type input
                                        setAcceptance_deadline(""); // Clear acceptance deadline input
                                        setDocInputVisible(false); // Hide inputs
                                      } else {
                                        swal("Error", "Please complete both fields", "error");
                                      }
                                    }}
                                  >
                                    Update
                                  </button>
                                </div>
                              )}
                            </td>
                            <td>
                              <OverlayTrigger
                                key="bottom"
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip">
                                    Add / View Notes
                                  </Tooltip>
                                }
                              >
                                <button
                                  onClick={() => {
                                    setActionModal({
                                      mail: false,
                                      notes: true,
                                      application: item?._id,
                                    });
                                    getAllNotesForAUser(item?._id);
                                  }}
                                  className="btn btn-primary-regular"
                                >
                                  Notes
                                </button>
                              </OverlayTrigger>{" "}
                              <button
                                className="btn btn-primary-regular"
                                onClick={() => {
                                  navigate(
                                    `/dashboard/user/createapplication/${item?._id}?edit=true&country=${item?.country}&universityName=${item?.universityName}&courseName=${item?.courseName}`
                                  );
                                }}
                              >
                                Edit
                              </button>
                              <button
                                className="ms-1 btn btn-danger"
                                onClick={() => {
                                  swal({
                                    title: "Are you sure?",
                                    text: "Once deleted, you will not be able to recover this application!",
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                  }).then((result) => {
                                    if (result) {
                                      delApplication(item?._id);
                                    }
                                  });
                                }}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={8}>
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id="headingTwo"
                                >
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#col${item?._id}`}
                                    aria-expanded="false"
                                    aria-controls={`col${item?._id}`}
                                  >
                                    Add Credentials
                                  </button>
                                </h2>
                                <div
                                  id={`col${item?._id}`}
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingTwo"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <CredentialModal
                                      id={item?._id}
                                      edit={false}
                                    />
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <br />
          <button
            className="btn btn-primary-regular"
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            Back
          </button>
        </div>
      </div>
      {/* Notes Modal */}
      <Modal
        show={actionModal?.notes}
        onHide={() => setActionModal({ mail: false, notes: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/View Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!allNotes?.loading ? (
            allNotes?.data?.map((itm, i) => (
              <div key={i}>
                <h2 style={{ fontWeight: "600" }}>{itm?.note}</h2>
                <p>
                  Created at:{" "}
                  {moment(itm?.createdAt).format("DD-MMM-YYYY HH:mm A")}
                </p>
              </div>
            ))
          ) : (
            <p>Loading...</p>
          )}
          <div className="form-floating">
            <input
              className="form-control"
              placeholder=""
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />{" "}
            <label for="floatingTextarea">Notes</label>
          </div>
          <br />
          <Button
            variant="primary-regular"
            disabled={!notes}
            onClick={() => {
              handleAddNotes();
            }}
          >
            Send
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setActionModal({ mail: false, notes: false })}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Mail Modal */}
      <Modal
        show={actionModal?.mail}
        onHide={() => setActionModal({ mail: false, notes: false })}
        className="modal-xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!allMails?.loading ? (
            allMails?.data?.map((itm, i) => (
              <div key={i}>
                {/* <h2>
                  <span style={{ fontWeight: "600" }}>To: </span>
                  {itm?.to}
                </h2> */}
                <h2>
                  <span style={{ fontWeight: "600" }}>Subject: </span>
                  {itm?.subject}
                </h2>
                <h2>
                  <span style={{ fontWeight: "600" }}>Body: </span>
                  {itm?.body}
                </h2>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="m-0">
                    Sent at:{" "}
                    {moment(itm?.createdAt).format("DD-MMM-YYYY HH:mm A")}
                  </p>
                  <button
                    className="btn btn-secondary"
                    onClick={() => handleReSendMail(itm?._id)}
                  >
                    Resend
                  </button>
                </div>

                <hr />
              </div>
            ))
          ) : (
            <p>Loading...</p>
          )}
          <h2>
            To: <span style={{ fontWeight: "600" }}>{actionModal?.email}</span>
          </h2>
          <input
            className="form-control"
            placeholder="Subject"
            value={mail.subject}
            onChange={(e) =>
              setMail({
                ...mail,
                subject: e.target.value,
              })
            }
          />{" "}
          <br />
          <label>From:</label>
          <select
            className="form-select"
            onChange={(e) => {
              setMail({
                ...mail,
                signature: fromEmails.find((itm) => itm.email == e.target.value)
                  ?.signature,
              });
            }}
          >
            <option value="">From Email</option>
            {fromEmails?.map((itm) => (
              <option key={itm?._id} value={itm?.email}>
                {itm?.email}
              </option>
            ))}
          </select>
          <br />
          <Editor data={mail} name="body" setData={setMail} />
          <br />
          <Editor data={mail} name="signature" setData={setMail} height={169} />
          {/* <textarea
            className="form-control"
            placeholder="Body"
            value={mail.body}
            onChange={(e) =>
              setMail({
                ...mail,
                body: e.target.value,
              })
            }
          /> */}
          <br />
          <Button
            variant="primary-regular"
            onClick={() => {
              handleSendMail();
            }}
          >
            Send
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setActionModal({ mail: false, notes: false })}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {
        // Credential Modal
      }
      <Modal show={credentials.modal} onHide={handleCloseCredModal}>
        <Modal.Header closeButton>
          <Modal.Title>Credentials</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CredentialModal
            email={credentials.email}
            url={credentials.url}
            password={credentials.password}
            id={credentials.id}
            data={credentials}
            edit={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCredModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserApplicaton;
