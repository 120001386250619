import React, { useEffect, useState } from "react";
import { url } from "../Constants";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import API from "../../Api/Api";
import countries from "../Assets/Data/countries.json";
import countryUni from "../Assets/Data/university.json";
import courseData from "../Assets/Data/coursecounseling.json";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";

const CreateApplication = () => {
  const { id } = useParams("");
  const [searchParams] = useSearchParams();
  const authToken = localStorage.getItem("token");
  const [data, setdata] = useState({
    country: "",
    universityName: "",
    courseName: "",
    subject: "",
    scholarshipAwardedAmount: '',
  });
  const [course, setCourse] = useState([]);

  const onChange = (event) => {
    setdata({ ...data, [event.target.name]: event.target.value });
  };
  const [universityData, setUniversityData] = useState([]);
  const [otherinput, setOtherinput] = useState(false);
  const navigate = useNavigate();

  const createApplication = async () => {
    try {
      const response = await API("post", `/application/admin/new`, {
        universityName: data.universityName,
        courseName: data.courseName,
        country: data.country,
        userId: id,
        scholarshipAwardedAmount: data.scholarshipAwardedAmount,
      });
      const res_json = response.data;
      if (res_json.success) {
        //console.log(res_json)
        navigate(`/dashboard/user/application/${id}`);
      }
    } catch (error) {}
  };

  const updateApplication = async () => {
    try {
      const response = await API("put", `/application/admin/update`, {
        ...data,
        appId: id,
      });
      const res_json = response.data;
      if (res_json.success) {
        //console.log(res_json)
        window.history.back();
      }
    } catch (error) {}
  };

  const universityOptions = universityData?.map((uni) => ({
    label: uni,
    value: uni,
  }));
  
  const handleUniversityChange = (selectedOption) => {
    setdata({ ...data, universityName: selectedOption?.value || "" });
  };

  useEffect(() => {
    const setUniData = async () => {
      const getUniversitydata = (await countryUni[data.country]) || [];
      setUniversityData(getUniversitydata);
    };
    setUniData();
  }, [data.country]);

  useEffect(() => {
    if (data.subject.length > 0) {
      setCourse(courseData[data.subject]);
    } else {
      setCourse([]);
    }
  }, [data.subject]);

  useEffect(() => {
    if (data.courseName === "Other") {
      setOtherinput(true);
      setdata({
        ...data,
        courseName: "",
      });
    }
  }, [data.courseName]);

  useEffect(() => {
    let sub = "";
    for (const key in courseData) {
      if (courseData.hasOwnProperty(key)) {
        const innercourseData = courseData[key];
        innercourseData?.map((itm) => {
          if (itm.name == searchParams.get("courseName")) {
            sub = key;
          }
        });
      }
    }
    let obj = {
      universityName: searchParams.get("universityName"),
      courseName: searchParams.get("courseName"),
      country: searchParams.get("country"),
      appId: id,
      subject: sub,
    };
    setdata(obj);
  }, []);

  return (
    <div className="container admin-createpage">
      <div className="main-heading">Create Application</div>
      <div className="row mt-3">
        <div className="col">
          <div className="form-group">
            <label htmlFor="" className="form-label mb-0">
              Country<span className="required">*</span>
            </label>
            <select
              type="text"
              className="form-select"
              placeholder="Country"
              aria-label="Country"
              name="country"
              value={data.country}
              onChange={onChange}
            >
              <option value={""}>Please Select</option>
              {countries?.map((itm) => (
                <option key={itm?.name} value={itm?.name}>
                  {itm?.name}{" "}
                </option>
              ))}
            </select>
            {/* <input
              type="text"
              className="form-control"
              placeholder="Country"
              aria-label="Country"
              name="country"
              value={data.country}
              onChange={onChange}
            /> */}
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="" className="form-label mb-0">
              University/College Name<span className="required">*</span>
            </label>
            <Select
              options={universityOptions}
              value={universityOptions.find((opt) => opt.value === data.universityName)}
              onChange={handleUniversityChange}
              className="form-customselect"
              placeholder="Select University"
              isClearable
            />
            {/* <input
              name="universityName"
              list="universitylist"
              className="form-select"
              value={data.universityName}
              onChange={onChange}
              placeholder="Select University"
            />
            <datalist id="universitylist">
              {universityData !== undefined &&
                universityData.map((getstate, index) => (
                  <option name="universityName" value={getstate} key={index}>
                    {getstate}
                  </option>
                ))}
            </datalist> */}
            {/* <input
              type="text"
              className="form-control"
              placeholder="University/College Name"
              aria-label="University/College Name"
              name="universityName"
              value={data.universityName}
              onChange={onChange}
            /> */}
          </div>
        </div>
      </div>
      <div className="row">
        {/* <div className="col">
            <div className="form-group">
                <label htmlFor="" className="form-label mb-0">
                    Intake<span className="required">*</span>
                </label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Intake"
                    aria-label="Intake"
                    name="intake"
                    value={data.intake}
                    onChange={onChange}
                />
            </div>
        </div> */}
        <div className="col">
          <label htmlFor="" className="form-label mb-0">
            Subject Name<span className="required">*</span>
          </label>
          <select
            className="form-select"
            name="subject"
            value={data.subject}
            onChange={onChange}
            placeholder="Select Subject"
          >
            <option value="">Select Subject</option>
            {Object?.keys(courseData).map((idx) => (
              <option key={idx}>{idx}</option>
            ))}
          </select>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="" className="form-label mb-0">
              Course Name<span className="required">*</span>
            </label>
            {otherinput ? (
              <input
                className="form-select"
                name="courseName"
                value={data.courseName}
                onChange={onChange}
                placeholder="Enter Custom Course Name"
              />
            ) : (
              <select
                className="form-select"
                name="courseName"
                value={data.courseName}
                onChange={onChange}
                placeholder="Select Subject"
              >
                <option value="">Select Course</option>
                {course.map((item) => (
                  <option key={item?.name}>{item?.name}</option>
                ))}
                <option key={"Other"}>Other</option>
              </select>
            )}
            {/* <input
              type="text"
              className="form-control"
              placeholder="Course Name"
              aria-label="Course Name"
              name="courseName"
              value={data.courseName}
              onChange={onChange}
            /> */}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label htmlFor="" className="form-label mb-0">
            Scholarship Awarded Amount ($)
          </label>
          <input
            className="form-control"
            name="scholarshipAwardedAmount"
            value={data.scholarshipAwardedAmount}
            onChange={onChange}
            placeholder="0"
          />
        </div>
        <div className="col"> </div>
      </div>
      <br />
      <div className="">
        {searchParams.get("edit") == "true" ? (
          <button
            type="submit"
            className="btn btn-primary-regular me-3"
            onClick={updateApplication}
          >
            Save
          </button>
        ) : (
          <button
            type="submit"
            className="btn btn-primary-regular me-3"
            onClick={createApplication}
          >
            Submit
          </button>
        )}

        <button
          className="btn btn-secondary-regular"
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default CreateApplication;
