import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { url } from "../Constants";
import subscription from "../Assets/Data/subscription.json";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import CheckoutForm from "./Checkout";
import API from "../../Api/Api";
import { loadStripe } from "@stripe/stripe-js";
import swal from "sweetalert";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PurchaseCredits = () => {
  const [orderData, setOrderData] = useState("");
  const navigate = useNavigate();
  const [currentPlan, setCurrentPlan] = useState({});
  const [userCurrentPlan, setUserCurrentPlan] = useState("");
  const [paymentScreen, setPaymentScreen] = useState(false);

  const [data, setData] = useState({
    lead_status: "",
  });

  const getData = async () => {
    try {
      const response = await fetch(`${url}/auth/verifyuser`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      const res_json = await response.json();
      const json = res_json.data;

      setUserCurrentPlan(json?.lead_status); // Extract lead_status as the current plan
      setData({
        ...data,
        lead_status: json?.lead_status,
      });
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    getData(); // Fetch data when the component mounts
  }, []);

  const purchasePlan = async (id) => {
    try {
      const data = await API(
        "post",
        "/subscription/user/purchase-subscription",
        {
          id: id,
        }
      );
      setOrderData(data?.data?.clientSecret);
      setPaymentScreen(true);
    } catch (error) {}
  };

  const refill = async (planid) => {
    try {
      await API("post", `/subscription/user/purchase-subscription/success`, {
        studentid: localStorage.getItem("_id"),
        subscriptionid: planid,
        paymentid: "free_plan_0_usd",
      });
      swal({
        title: "Subscription successfully!",
        text: "Subscription successfull, you will be redirected to dashboard and your subscription will be active.",
        icon: "success",
      }).then((res) => {
        if (res) {
          navigate("/userdashboard");
        }
      });
    } catch (error) {}
  };

  const logout = async () => {
    navigate("/login");
    localStorage.removeItem("token");
    localStorage.clear();
  };

  const planMapping = {
    registeredpremium: "premium0plan",
    registeredelite: "elite199plan",
    registeredplatinum: "platinum399plan"
  };

  if (paymentScreen) {
    return (
      <div>
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: orderData,
          }}
        >
          {" "}
          <CheckoutForm itm={currentPlan} />
        </Elements>
      </div>
    );
  }

  return (
    <>
    <header className="fixed-top fixed-top d-flex justify-content-between">
          <Link to={"/userdashboard"}>
            <img
              src={require("../Assets/Media/Images/logo.png")}
              alt="logo-rise"
              className="header-logo"
              title="Rise Higher Education"
            />
          </Link>
    <a href="" className="btn-secondary-regular float-end m-3" onClick={logout}>
            Logout
          </a>
          </header>
      <main id="content" className="mentor-insidepage">
        <section
          id="pricing"
          className="secondary-color text-center scrollto clearfix"
        >
          <div className="">
            <div className="mentor-section-heading">
              <h3>YOUR CHOICE</h3>
              <h2 className="mentor-section-title">
                We have the Subscription for you
              </h2>
              <span className="subscription-plan-name">Current Plan: {
    userCurrentPlan === "registeredplatinum" ? "Platinum" :
    userCurrentPlan === "registeredelite" ? "Elite" :
    userCurrentPlan === "registeredpremium" ? "Premium" :
    "None"
  }</span>
            </div>
            <div className="subscription-plan">
              {subscription?.map((itm) => (
                
                
                <div
                  className={`pricing-block mentor-col-3 wow fadeInUp`}
                  data-wow-delay="0.4s"
                  key={itm?.id}
                >
                  <div className={`pricing-block-content ${
                    planMapping[userCurrentPlan] === itm?.id ? "active-plan" : ""
                  }`}>
                    <h3>{itm?.name}</h3>
                    <p className="pricing-sub">{itm?.tag}</p>
                    <div className="price">
                        <span>$</span>
                        {itm?.price}
                      </div>
                    <div className="pricing">
                      <p>{itm?.description}</p>
                    </div>
                    <ul>
                      {itm?.benefits?.map((feature) => (
                        <li>
                          <strong>{feature?.name}</strong>
                          <p>{feature?.desc}</p>
                        </li>
                      ))}
                    </ul>
                    {(userCurrentPlan === "registeredpremium" && (itm?.id === "elite199plan" || itm?.id === "platinum399plan")) ||
                      (userCurrentPlan === "registeredelite" && itm?.id === "platinum399plan") ||
                      (!["registeredpremium", "registeredelite", "registeredplatinum"].includes(userCurrentPlan)) ? (
                      <button
                        className="mentor-button-sm"
                        onClick={() => {
                          if (Number(itm?.price) === 0) {
                            refill(itm?.id);
                          } else {
                            purchasePlan(itm?.id);
                            setCurrentPlan(itm);
                          }
                        }}
                      >
                        Subscribe Now
                      </button>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default PurchaseCredits;
